import { put, call, all } from "redux-saga/effects";
import * as actions from "../actions";
import axios from "axios";
import history from "../../utils/history";
function getIdentity(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/v1/landing/identity`,
      data,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
function getMajor(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/v1/landing/major`,
      data,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}
export function* identityWatcher() {
  try {
    const dataIdentity = yield call(getIdentity, {
      identity_url: "smknegeri1katapang.simpleschooldev.com",
    });
    const dataMajor = yield call(getMajor, {
      identity_id:
        dataIdentity && dataIdentity.data.serve
          ? dataIdentity.data.serve.data.identity_id
          : "",
      key_sort: "created_at",
      sort: "desc",
      limit: 999999,
    });
    yield all([
      put(
        actions.generalSetIdentity(
          dataIdentity && dataIdentity.data.serve
            ? dataIdentity.data.serve
            : null
        )
      ),
      put(
        actions.generalSetMajor(
          dataMajor && dataMajor.data.serve ? dataMajor.data.serve.data : null
        )
      ),
    ]);
    history.push(history.location.state.from);
  } catch (err) {
    console.log(err);
  }
}
