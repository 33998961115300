export const generalIdentityReducer = (state = {}, action) => {
  switch (action.type) {
    case "IDENTITY_GET":
      return state;
    case "IDENTITY_SET":
      return {
        ...state,
        identity: action.identity,
      };
    default:
      return state;
  }
};

export const generalMajorReducer = (state = [], action) => {
  switch (action.type) {
    case "MAJOR_GET":
      return state;
    case "MAJOR_SET":
      return {
        ...state,
        major: action.major,
      };
    default:
      return state;
  }
};
