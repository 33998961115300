/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import helper from "../utils/helper";
import { notification, Spin } from "antd";
import Router from "./router";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openBackdrop: false,
    };
  }

  UNSAFE_componentWillMount() {
    axios.interceptors.request.use(
      (config) => {
        this.setState({
          openBackdrop: true,
        });
        return config;
      },
      (error) => {
        if (error) {
          this.setState({
            openBackdrop: false,
          });
          notification.error({
            message: error.response
              ? `${error.response.data.message}`
              : "Something wrong with our server, please try again...",
            placement: "bottomRight",
            duration: 3,
          });
        }
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        this.setState({
          openBackdrop: false,
        });
        return response;
      },
      (error) => {
        if (error) {
          this.setState({
            openBackdrop: false,
          });
          notification.error({
            message: error.response
              ? `${error.response.data.message}`
              : "Something wrong with our server, please try again...",
            placement: "bottomRight",
            duration: 3,
          });
        }
        return Promise.reject(error);
      }
    );
    this.props.getIdentity();
  }

  render() {
    return (
      <Spin
        spinning={this.state.openBackdrop}
        tip="Loading..."
        className="h-full"
      >
        <Router />
      </Spin>
    );
  }
}

export default connect(helper.mapStateToProps, helper.mapDispatchToProps)(Main);
