export const generalGetIdentity = (identity) => ({
  type: "IDENTITY_GET",
  identity,
});

export const generalSetIdentity = (identity) => ({
  type: "IDENTITY_SET",
  identity,
});

export const generalGetMajor = (major) => ({
  type: "MAJOR_GET",
  major,
});

export const generalSetMajor = (major) => ({
  type: "MAJOR_SET",
  major,
});
