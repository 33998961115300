import React from "react";
import { BrowserRouter, Router } from "react-router-dom";
import ClearCache from "react-clear-cache";
import history from "./utils/history";
import Main from "./apps/main";
import BlankCacheComponent from "./components/BlankCacheComponent";
import "./App.css";
import "antd/dist/antd.css";
const App = () => (
  <ClearCache>
    {({ isLatestVersion, emptyCacheStorage }) =>
      !isLatestVersion ? (
        <BlankCacheComponent emptyCacheStorage={emptyCacheStorage} />
      ) : (
        <BrowserRouter>
          <Router history={history}>
            <Main />
          </Router>
        </BrowserRouter>
      )
    }
  </ClearCache>
);

export default App;
