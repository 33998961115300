import React, { Component } from "react";
import { Result, Button } from "antd";
import { connect } from "react-redux";
import helper from "../utils/helper";
class BlankCacheComponent extends Component {
  render() {
    return (
      <Result
        status="warning"
        title="Update Available!"
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => this.props.emptyCacheStorage()}
          >
            Update Version
          </Button>
        }
      />
    );
  }
}
export default connect(
  helper.mapStateToProps,
  helper.mapDispatchToProps
)(BlankCacheComponent);
