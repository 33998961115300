/* eslint-disable react/prefer-stateless-function */
import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import helper from "../utils/helper";
import Error from "../pages/Error";

const Home = lazy(() => import("../pages/Home"));
const Sejarah = lazy(() => import("../pages/Sejarah"));
const VisiMisi = lazy(() => import("../pages/VisiMisi"));
const ProfilePimpinan = lazy(() => import("../pages/ProfilePimpinan"));
const Ekstrakurikuler = lazy(() => import("../pages/Ekstrakurikuler"));
const Kurikulum = lazy(() => import("../pages/Kurikulum"));
const Kesiswaan = lazy(() => import("../pages/Kesiswaan"));
const Sarana = lazy(() => import("../pages/Sarana"));
const HubunganIndustri = lazy(() => import("../pages/HubunganIndustri"));
const Berita = lazy(() => import("../pages/Berita"));
const BeritaDetail = lazy(() => import("../pages/BeritaDetail"));
const GalleryPhoto = lazy(() => import("../pages/GalleryPhoto"));
const GalleryVideo = lazy(() => import("../pages/GalleryVideo"));
const Jurusan = lazy(() => import("../pages/Jurusan"));
const Berkas = lazy(() => import("../pages/Berkas"));
const TeachingFactory = lazy(() => import("../pages/TeachingFactory"));
const Alumni = lazy(() => import("../pages/Alumni"));
const Ppdb = lazy(() => import("../pages/Ppdb"));
const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);
const ProtectedRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.identity ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/blank",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
class Router extends React.Component {
  render() {
    return (
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/blank" render={() => <Error />} />
          <ProtectedRoute path="/home" identity={this.props.identity}>
            <Home />
          </ProtectedRoute>
          <ProtectedRoute
            path="/sejarah-singkat"
            identity={this.props.identity}
          >
            <Sejarah />
          </ProtectedRoute>
          <ProtectedRoute path="/visi-misi" identity={this.props.identity}>
            <VisiMisi />
          </ProtectedRoute>
          <ProtectedRoute
            path="/registrasi-alumni"
            identity={this.props.identity}
          >
            <Alumni />
          </ProtectedRoute>
          <ProtectedRoute
            path="/profile-pimpinan"
            identity={this.props.identity}
          >
            <ProfilePimpinan />
          </ProtectedRoute>
          <ProtectedRoute
            path="/ekstrakurikuler"
            identity={this.props.identity}
          >
            <Ekstrakurikuler title={"Semua Ekstrakurikuler"} />
          </ProtectedRoute>
          <Route
            exact
            path="/kategori-ekstrakurikuler-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <Ekstrakurikuler
                  title="Ekstrakurikuler berdasarkan kategori"
                  category={props.match.params.id}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <ProtectedRoute path="/kurikulum" identity={this.props.identity}>
            <Kurikulum />
          </ProtectedRoute>
          <ProtectedRoute path="/kesiswaan" identity={this.props.identity}>
            <Kesiswaan />
          </ProtectedRoute>
          <ProtectedRoute path="/ppdb" identity={this.props.identity}>
            <Ppdb />
          </ProtectedRoute>
          <ProtectedRoute
            path="/teaching-factory"
            identity={this.props.identity}
          >
            <TeachingFactory />
          </ProtectedRoute>
          <ProtectedRoute
            path="/sarana-prasarana"
            identity={this.props.identity}
          >
            <Sarana />
          </ProtectedRoute>
          <ProtectedRoute
            path="/hubungan-industri"
            identity={this.props.identity}
          >
            <HubunganIndustri />
          </ProtectedRoute>
          <ProtectedRoute path="/berita" identity={this.props.identity}>
            <Berita title={"Semua Berita"} />
          </ProtectedRoute>
          <ProtectedRoute path="/photo" identity={this.props.identity}>
            <GalleryPhoto title={"Semua Gallery"} />
          </ProtectedRoute>
          <Route
            exact
            path="/kategori-gallery-photo-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <GalleryPhoto
                  title="Gallery berdasarkan kategori"
                  category={props.match.params.id}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <ProtectedRoute path="/video" identity={this.props.identity}>
            <GalleryVideo title={"Semua Gallery"} />
          </ProtectedRoute>
          <Route
            exact
            path="/kategori-gallery-video-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <GalleryVideo
                  title="Gallery berdasarkan kategori"
                  category={props.match.params.id}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <ProtectedRoute path="/file" identity={this.props.identity}>
            <Berkas title={"Semua File"} />
          </ProtectedRoute>
          <Route
            exact
            path="/kategori-file-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <Berkas
                  title="File berdasarkan kategori"
                  category={props.match.params.id}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/kategori-berita-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <Berita
                  title="Berita berdasarkan kategori"
                  category={props.match.params.id}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/search-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <Berita
                  title={`Hasil pencarian ${decodeURIComponent(
                    props.match.params.id
                  )}`}
                  search={props.match.params.id}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/berita-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <BeritaDetail title={"Berita"} route={props.match.params.id} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
          <Route
            exact
            path="/jurusan-:id?"
            render={(props) =>
              this.props.identity && props.match.params.id ? (
                <Jurusan title="Jurusan" category={props.match.params.id} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/blank",
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        </Switch>
      </Suspense>
    );
  }
}
export default connect(
  helper.mapStateToProps,
  helper.mapDispatchToProps
)(withRouter(Router));
